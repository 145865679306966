import React from 'react';
import { Link } from 'gatsby'
import Img from 'gatsby-image';
import Layout from "../layouts/index";
import '../style/index.scss';
import { graphql } from 'gatsby';
import i18next from 'i18next';
import SEO from '../components/seo'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

let noImage = `${ process.env.GATSBY_NO_IMAGE_URL}`;

/**
 * @function addNoImage
 * @desc this function change the src image to noImage when the image is broken
 * 
 * @param {e}
 */
function addNoImage(e) {
    e.target.src = noImage;
}

class BrandsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const data = this.props.data;
        const {
            breadcrumb: { crumbs },
        } = this.props.pageContext;
        return(
            <Layout children={null} data={data}>
                <SEO 
                    title={data.category.name}
                    keywords=''
                    slug={typeof window !== `undefined` ? window.location.href.replace(window.location.origin,'').replace('/' + data.category.locale + '/','') : ''}
                    description={data.category.name}
                    lang={data.category.locale}
                    contactinfo={JSON.parse(data.contactinfo.data)}
                />
                <Breadcrumb crumbs={crumbs} crumbSeparator=" > " hiddenCrumbs={['/en', '/es']} crumbLabel={data.category.name} />
                <div className="plp">
                    <div className="plp__category-name section-title">
                        <h1>{data.category.name}</h1>
                    </div>
                    
                    <div className="plp__wrapper">
                        <div className="plp__category">
                            <div className="plp__grid subcategories">
                            {                
                                data.category.subcategory.length > 0 ?
                                    data.category.subcategory.sort((a, b) => {return a.name > b.name ? 1:-1}).map((subcategory, index) => (
                                        <div key={index} className="plp__subcategory">
                                            <Link to={'/' + subcategory.locale + '/' +  data.category.url + '/' + subcategory.url} className="plp__subcategory-title">
                                                { subcategory.image ? (
                                                        subcategory.image.fluid ?
                                                            <Img fluid={subcategory.image.fluid} alt={subcategory.name} loading="lazy" fadeIn/>
                                                        : subcategory.image.url &&
                                                        <img onError={addNoImage} className="lazyload" data-src={subcategory.image.url ? subcategory.image.url : noImage} alt={subcategory.name}/>
                                                    )
                                                    : <span>{subcategory.name.replace('Sunglasses ','').replace('Frames ','').replace('Gafas de sol ','').replace('Gafas ','')}</span>
                                                }
                                            </Link>
                                        </div>
                                    ))
                                :
                                    <div className="plp__not-found">
                                        <span>{i18next.t("FILTER_NO_BRANDS_FOUND")}</span>
                                    </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

/* CategoryQuery */
export const query = graphql`
query ($categoryId: String!, $locale: String!) {
    category: datoCmsCategory(id: {eq: $categoryId }) {
      id
      name
      sequence
      locale
      url
      subcategory {
        id
        name
        url
        locale
        image {
            url
            fluid(maxWidth: 650) {
                ...GatsbyDatoCmsFluid
            }
          }
        sequence
      }
    }
    categories: allDatoCmsCategory(sort: {order: ASC, fields: sequence}, filter: {top: {eq: true}, locale: {eq: $locale}}) {
      edges {
        node {
          id
          name
          url
          sequence
          locale
          subcategory {
            id
            name
            url
            locale
            sequence
            subcategory {
              id
              name
              url
              locale
              sequence
            }
          }
        }
      }
    }
    contentPages: allDatoCmsContentPage(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                locale
                url
                cpContentGridTitle
            }
        }
    }
    allDatoCmsProduct(filter: {locale: {eq: $locale}}, sort: {fields: modelo}) {
        edges {
            node {
                id
                descripcion
                descripcionampliada
                categoria
                categorias {
                    id
                    name
                    url
                    locale
                    sequence
                }
                marca
                modelo
                colorcrist
                colormont
                forma
                material
                sexo
                polarizacion
                calibre
                puente
                varilla
                idarticulo
                images
                weight
                offerpriceeur
                offerpricegbp
                offerpriceusd
                listpriceeur
                listpricegbp
                listpriceusd
                crosssell
                meta {
                  createdAt
                }
            }
        }
    }
    allDatoCmsFooter(filter: {locale: {eq: $locale}}) {
      edges {
        node {
            footerLinksList
            footerCopyright
            footerPayments
        }
      }
    }
    contactinfo : datoCmsExtrainfo(name: {eq: "contactinfo"}, locale: {eq: $locale}) {
        data
    }
    basemarketing : datoCmsExtrainfo(name: {eq: "plp-basicdata"}, locale: {eq: $locale}) {
        text
    }
    ddm : allDatoCmsDdm(filter: {locale: {eq: $locale}}) {
        nodes {
            submenu {
                header
                directLink
                menu
            }
        }
    }
  }
  `

export default BrandsPage